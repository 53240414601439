




















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component({
  components: {}
})
class ProjectCardRow extends Vue {
  @Prop() name!: string
  @Prop() secondName!: string
  @Prop() value!: string
  @Prop() secondValue!: string
  @Prop() exporoClass!: boolean

  private completeName: string = ''
  private completeValue: string = ''
  private avatarColor: string = 'black'

  private setNamesAndValues(): void {
    if (this.secondName) {
      this.completeName = `${this.name}/ ${this.secondName}`
      this.completeValue = this.secondValue
        ? `${this.value} / ${this.secondValue}`
        : `${this.value} / - `
    } else {
      this.completeName = this.name
      this.completeValue = this.value
    }
  }

  private setAvatarColor(): void {
    switch (this.value) {
      case 'AA':
        this.avatarColor = '#b6c1cf'
        break
      case 'A':
        this.avatarColor = '#274a7f'
        break
      case 'B':
        this.avatarColor = '#1e73be'
        break
      case 'C':
        this.avatarColor = '#82bc24'
        break
      case 'D':
        this.avatarColor = '#c1da00'
        break
      case 'E':
        this.avatarColor = '#fae100'
        break
      case 'F':
        this.avatarColor = '#fba609'
        break
      default:
        this.avatarColor = 'black'
    }
  }

  mounted() {
    this.setNamesAndValues()
    if (this.exporoClass) this.setAvatarColor()
  }
}

export default ProjectCardRow
