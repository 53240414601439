











import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import { StatusType } from '@exporo/financing_project_type_orm/dist/interfaces/Status'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import ProjectCardRow from '~/components/ProjectCard/ProjectCardRow.vue'
import { TYPE_BOND, TYPE_PROJECT } from '~/components/PusherJS/PusherService'
const ProgressBar = () => import('~/components/ProgressBar/ProgressBar.vue')

@Component({
  components: {
    Container,
    ProjectCardRow,
    ProgressBar
  }
})
export default class ProjectCardFoot extends Vue {
  @Prop()
  data!: ProjectMetaData

  shouldShowProgress(): boolean {
    return this.data.status === StatusType.IN_FUNDING
  }

  shouldSowComingSoon(): boolean {
    return this.data.status === StatusType.COMING_SOON
  }

  // @TODO: should be replaces by project.type
  getType() {
    let isLoan = true

    if (this.data && this.data.investmentType) {
      isLoan = this.data.investmentType !== 'Anleihe'
    }

    return isLoan ? TYPE_PROJECT : TYPE_BOND
  }
}
