
























import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import ModuleGroupInterface from '@exporo/financing_project_type_orm/dist/interfaces/ToggleSectionModules/ModuleGroup'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'
import ModuleGroup from '~/components/ToggleableContent/ModuleGroup.vue'
import ToggleSectionSkeleton from '~/components/ToggleableContent/ToggleSectionSkeleton.vue'

@Component({
  components: {
    ToggleSectionSkeleton,
    ModuleGroup,
    Container
  }
})
class ToggleSection extends Vue {
  @Prop()
  private projectData!: ProjectData

  private getFilteredModuleGroups(
    isTeaser = true,
    newData?: ProjectData
  ): ModuleGroupInterface[] | null {
    if (newData) {
      return (
        newData.data?.toggleSection?.moduleGroups.filter((m) => {
          return m.isTeaser === isTeaser
        }) || []
      )
    }
    return (
      this.projectData?.data?.toggleSection?.moduleGroups.filter((m) => {
        return m.isTeaser === isTeaser
      }) || []
    )
  }

  private teaserModuleGroups:
    | ModuleGroupInterface[]
    | null = this.getFilteredModuleGroups()

  private nonTeaserModuleGroup:
    | ModuleGroupInterface[]
    | null = this.getFilteredModuleGroups(false)

  @Watch('projectData')
  private dataChanged(
    newData: ProjectData
    // oldData: ProjectData
  ) {
    this.teaserModuleGroups = this.getFilteredModuleGroups(true, newData)
    this.nonTeaserModuleGroup = this.getFilteredModuleGroups(false, newData)
  }
}

export default ToggleSection
