













import { Component, Vue, Prop } from 'nuxt-property-decorator'
import Slide from '~/components/Carousel/Slide.vue'
import MediaItem from '~/components/Carousel/interfaces/MediaItem'

@Component({
  components: {
    Slide
  }
})
class Carousel extends Vue {
  @Prop()
  private data!: MediaItem[]

  @Prop({ default: 500 })
  private height!: string | number

  @Prop({ default: false })
  private continuous!: boolean

  @Prop({ default: false })
  private stage!: boolean
}

export default Carousel
