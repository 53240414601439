














import { Component, Vue, Prop } from 'nuxt-property-decorator'
import MapBoxData, { LngLatLike } from '~/components/MapBox/MapBoxData'

let classInstanceNumber = 0
declare let mapboxgl: any

@Component
class MapBox extends Vue {
  @Prop() data!: MapBoxData

  private map!: any
  private mapContainerId: string = ''
  private thumbnailURL: null | string = null

  beforeMount() {
    this.mapContainerId = `map-container-${classInstanceNumber}`
    classInstanceNumber += 1
  }

  mounted() {
    const head = document.getElementsByTagName('head')[0]
    const s: any = document.createElement('script')
    s.src = 'https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.js'
    s.type = 'text/javascript'
    s.async = !0
    const initMap = this.initMap.bind(this)
    s.onload = s.onreadystatechange = function() {
      initMap()
    }
    head.appendChild(s)
  }

  initMap() {
    this.createMap()
    this.setMarkers()
    this.map.on('load', function(e: any) {
      e.target.resize()
    })
  }

  setMarkers() {
    this.data.markers.forEach((coordinates: LngLatLike) => {
      const el = document.createElement('div')
      const arrow = document.createElement('span')
      el.className = 'marker'
      el.innerHTML = this.data?.title ?? ' '
      el.appendChild(arrow)

      new mapboxgl.Marker(el).setLngLat(coordinates).addTo(this.map)
    })
  }

  createMap() {
    mapboxgl.accessToken = process.env.mapBoxAccessToken!

    this.map = new mapboxgl.Map({
      container: this.mapContainerId,
      style: 'mapbox://styles/exporomap/ckgwcxm9e17gx19pd179c57ir',
      center: this.data.center,
      zoom: this.data.zoom,
      padding: 100
    })

    if (window.innerWidth >= 1200) {
      this.thumbnailURL = this.createThumbnailURL({
        width: 240,
        height: 240,
        center: this.data.center
      })
    }

    if (window.innerWidth >= 500) {
      const nav = new mapboxgl.NavigationControl()
      this.map.addControl(nav, 'top-left')
    }

    this.map.scrollZoom.disable()
  }

  createThumbnailURL(options: { width: number; height: number; center: any }) {
    const lat: any = options.center[Object.keys(options.center)[0]]
    const lng: any = options.center[Object.keys(options.center)[1]]

    return lat && lng
      ? `https://api.mapbox.com/styles/v1/exporomap/cjjo71ajx5udx2rpuzav2qh7i/static/${lng},${lat},15,0,0/${options.width}x${options.width}?access_token=pk.eyJ1IjoiZXhwb3JvbWFwIiwiYSI6ImNqam81ajJnMDBvYzgzcW5sdDBuaDl0bWYifQ.ONMoKE3p4K0ipnCAWXygPQ`
      : null
  }
}

export default MapBox
