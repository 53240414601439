










import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Plyr from 'plyr'
import MediaDialogEventManager from '~/components/MediaDialog/MediaDialogEventManager'
let videoPlayerId = 0

@Component
class VideoPlayer extends Vue {
  @Prop()
  private embedId!: string

  @Prop({ default: 'vimeo' })
  private provider!: string

  private player: Plyr | null = null

  private playerId: string = ''

  pause(): void {
    if (this.player) this.player.pause()
  }

  beforeMount(): void {
    this.playerId = `video-player-${videoPlayerId}-${this.provider}-${this.embedId}`
    videoPlayerId += 1
  }

  mounted(): void {
    this.player = new Plyr(`#${this.playerId}`)
    MediaDialogEventManager.subscribe(this.pause)
  }
}

export default VideoPlayer
