


















import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  components: {}
})
class Process extends Vue {
  private imageUrl: string = ''

  mounted(): void {
    if (window.innerWidth < 600) {
      this.imageUrl = `https://s3.eu-central-1.amazonaws.com/media.financing.exporo.io/GFK+Finanzierung+So+funktionierts_B.jpg?=${window.innerWidth}`
    } else {
      this.imageUrl = `https://s3.eu-central-1.amazonaws.com/media.financing.exporo.io/GFK+Finanzierung+So+funktionierts_A.jpg?=${window.innerWidth}`
    }
  }
}

export default Process
