









































import { Component, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import axios from 'axios'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import ProjectCard from '~/components/ProjectCard/ProjectCard.vue'
import EmptyState from '~/components/Financing/EmptyState/EmptyState.vue'

enum typeOfCapacities {
  notFull,
  full,
  surpassed
}

@Component({
  components: {
    ProjectCard,
    Container,
    EmptyState
  }
})
class ActiveProjects extends Vue {
  private projects: ProjectMetaData[] | null = null
  private numberOfProjectsPerCarouselPage: number = 0
  private nextArrow: string | boolean = false
  private prevArrow: string | boolean = false
  private capacity: typeOfCapacities = typeOfCapacities.notFull
  private isMobile: boolean = false

  private setNumberOfProjectsPerCarouselPage(screenWidth: number) {
    switch (true) {
      case screenWidth <= 600:
        this.numberOfProjectsPerCarouselPage = 1
        break
      case screenWidth <= 960:
        this.numberOfProjectsPerCarouselPage = 2
        break
      case screenWidth <= 1264:
        this.numberOfProjectsPerCarouselPage = 2
        break
      case screenWidth <= 1904:
        this.numberOfProjectsPerCarouselPage = 3
        break
      default:
        this.numberOfProjectsPerCarouselPage = 3
        break
    }
  }

  private toggleArrows(currentSlide: number) {
    if (this.capacity === typeOfCapacities.surpassed) {
      if (
        (currentSlide + 1) * this.numberOfProjectsPerCarouselPage >=
        (this.projects?.length ?? 0)
      ) {
        this.nextArrow = false
      } else {
        this.nextArrow = 'mdi-chevron-right'
      }

      if (currentSlide === 0) {
        this.prevArrow = false
      } else {
        this.prevArrow = 'mdi-chevron-left'
      }
    }
  }

  private getRowJustify(): string | undefined {
    if (this.isMobile) return 'center'
    if (this.capacity === typeOfCapacities.notFull) return 'start'
    if (this.capacity === typeOfCapacities.full) return 'space-between'
    if (this.capacity === typeOfCapacities.surpassed) return 'space-around'
  }

  private getRowPadding(): string | undefined {
    if (this.isMobile) return 'px-0'
    if (
      this.capacity === typeOfCapacities.notFull ||
      this.capacity === typeOfCapacities.full
    )
      return 'pl-2 pr-4'
    if (this.capacity === typeOfCapacities.surpassed) return 'px-12'
  }

  private getColPadding(): string | undefined {
    if (this.isMobile) return 'pa-0 ActiveProjects__col--center'
    if (
      this.capacity === typeOfCapacities.notFull ||
      this.capacity === typeOfCapacities.full
    ) {
      return 'pl-2 pr-4'
    }
    if (this.capacity === typeOfCapacities.surpassed)
      return 'px-5 ActiveProjects__col--center'
  }

  private setCapacity() {
    if (this.projects) {
      if (this.projects.length < this.numberOfProjectsPerCarouselPage) {
        this.capacity = typeOfCapacities.notFull
      } else if (
        this.projects.length === this.numberOfProjectsPerCarouselPage
      ) {
        this.capacity = typeOfCapacities.full
      } else {
        this.capacity = typeOfCapacities.surpassed
      }
    }
  }

  sortProjectsByLastUpdated(projects: ProjectMetaData[]): ProjectMetaData[] {
    return projects.sort(function(a, b) {
      const aDate = new Date(a.lastUpdated)
      const bDate = new Date(b.lastUpdated)
      if (aDate < bDate) {
        return 1
      }
      if (aDate > bDate) {
        return -1
      }
      return 0
    })
  }

  async setProjects() {
    try {
      const response = await axios.get(
        `${process.env.backendApiBaseUrl}/v1/projects/meta/active`
      )
      const sortedProjects = this.sortProjectsByLastUpdated(response.data)

      this.projects = sortedProjects.filter(
        (p: ProjectMetaData) =>
          this.$router.currentRoute?.params?.slug !== p.slug && !p.hidden
      )

      if (this.projects != null && this.projects.length > 0) {
        this.$emit('active-projects-not-empty')
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  private setIsMobile(): void {
    const mediaQuery = (this as any).$mq
    this.isMobile = mediaQuery === 'mobile'
  }

  async mounted() {
    await this.setProjects()
    this.setIsMobile()
    this.setNumberOfProjectsPerCarouselPage(window.innerWidth)
    this.setCapacity()
    this.toggleArrows(0)
  }
}

export default ActiveProjects
