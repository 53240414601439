




























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ContactboxInterface from '@exporo/financing_project_type_orm/dist/interfaces/Contactbox'
import { Container } from '@exporo/educts-component-grid'
import AvatarSection from '~/components/FixContent/ContactBox/AvatarSection.vue'

@Component({
  components: {
    AvatarSection,
    Container
  }
})
class ContactBox extends Vue {
  @Prop()
  data!: ContactboxInterface

  private isMobileOrTablet(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile' || mediaQuery === 'tablet'
  }
}

export default ContactBox
