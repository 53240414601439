




import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ExporoClassFull from '~/components/ProjectDetailTemplate/StatusTemplates/BaseTemplate/ExporoClassFull.vue'

@Component({
  components: {
    ExporoClassFull
  }
})
class BubbleGroup extends Vue {
  @Prop() private data!: {
    selectedValue: string
  }

  get exporoClass() {
    return this.data.selectedValue
  }
}

export default BubbleGroup
