






























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'

@Component({
  components: {
    Container
  }
})
class List extends Vue {
  @Prop()
  listItems!: string[]

  @Prop({ default: false })
  dark!: boolean
}

export default List
