



































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BubbleItem from '~/components/BubbleRating/interfaces/BubbleItem'

@Component
class Bubble extends Vue {
  @Prop() private data!: BubbleItem
  @Prop({ default: false }) private preview!: boolean
  @Prop({ default: false }) private text!: boolean
  @Prop() private selected!: boolean
  pointIndex: number = this.data.title.search('Punkte')
  bubbleRadio: number = 0
  innerWidth: number = window.innerWidth
  mounted() {
    this.bubbleRadio = this.calculateBubbleRadius(this.innerWidth)
  }

  getColor() {
    if (this.preview) {
      return this.data.color
    }
    return this.selected ? 'rgb(61, 91, 173)' : 'rgb(193, 204, 230)'
  }

  calculateBubbleRadius(innerWidth: number) {
    switch (true) {
      case innerWidth <= 320:
        return 39
      case innerWidth <= 370:
        return 42
      case innerWidth >= 370:
        return 45
      case innerWidth >= 460:
        return 55
      case innerWidth >= 480:
        return 60
      default:
        return 80
    }
  }
}

export default Bubble
