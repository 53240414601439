














































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IconGroupType from '@exporo/financing_project_type_orm/dist/interfaces/ToggleSectionModules/IconGroup'
import Icon from '~/components/Icon/Icon.vue'
import ExporoClassIcons from '~/components/IconGroup/ExporoClassIcons.vue'

@Component({
  components: { Icon, ExporoClassIcons }
})
class IconGroup extends Vue {
  @Prop()
  private data!: IconGroupType[]

  @Prop()
  private moduleGroupTitle!: string

  get criteriaIcons() {
    return this.data.filter((criteriaIcon: any) => {
      return criteriaIcon.icon && criteriaIcon.title
    })
  }

  private isMobile(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile'
  }

  get isExporoClass() {
    return this.moduleGroupTitle === 'Exporo Klasse'
  }
}

export default IconGroup
