









































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import ProConListType from '@exporo/financing_project_type_orm/dist/interfaces/ToggleSectionModules/ProConList'

@Component({
  components: {
    Container
  }
})
class ProConList extends Vue {
  @Prop()
  data!: ProConListType
}

export default ProConList
