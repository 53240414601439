import { render, staticRenderFns } from "./ExporoClass.vue?vue&type=template&id=09fb0164&scoped=true&"
import script from "./ExporoClass.vue?vue&type=script&lang=ts&"
export * from "./ExporoClass.vue?vue&type=script&lang=ts&"
import style0 from "./ExporoClass.vue?vue&type=style&index=0&id=09fb0164&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09fb0164",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
installComponents(component, {VAvatar})
