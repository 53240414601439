





















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IconGroupType from '@exporo/financing_project_type_orm/dist/interfaces/ToggleSectionModules/IconGroup'
import Icon from '~/components/Icon/Icon.vue'

@Component({
  components: { Icon }
})
class ExporoClassIcon extends Vue {
  @Prop()
  private criteriaIcon!: IconGroupType

  private isMobile(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile' || mediaQuery === 'laptop'
  }
}

export default ExporoClassIcon
