










































































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import CardData from '@exporo/financing_project_type_orm/dist/interfaces/CardData'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import CtaButtonSkeleton from '~/components/CtaButton/CtaButtonSkeleton.vue'
import ExporoClassFull from '~/components/ProjectDetailTemplate/StatusTemplates/BaseTemplate/ExporoClassFull.vue'

const CtaButton = () => import('~/components/CtaButton/CtaButton.vue')
const ProgressBar = () => import('~/components/ProgressBar/ProgressBar.vue')

@Component({
  components: { ProgressBar, CtaButton, CtaButtonSkeleton, ExporoClassFull }
})
class Card extends Vue {
  @Prop({ required: true })
  private readonly data!: CardData

  @Prop()
  private project!: ProjectMetaData

  @Prop({ default: null })
  private readonly width!: string | null

  @Prop({ default: null })
  private readonly type!: string

  @Prop({ default: null })
  private readonly id!: string | null

  @Prop({ default: false })
  private readonly raised!: boolean

  @Prop({ default: 6 })
  private readonly numberOfTableRowsDesktop!: number

  @Prop({ default: 2 })
  private readonly numberOfMinTableRowsMobile!: number

  @Prop()
  private readonly template!: string

  private bottomSectionVisible = false

  private isMounted: boolean = false

  mounted() {
    this.isMounted = true
  }

  private isMobileOrTablet(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile' || mediaQuery === 'tablet'
  }

  get checkoutUrl(): string {
    return this.data?.financingCheckoutSlug
      ? this.data.financingCheckoutSlug + '?referrer=' + window.location.href
      : ''
  }

  get numberOfTableRows(): number {
    return this.isMobileOrTablet()
      ? this.numberOfMinTableRowsMobile
      : this.numberOfTableRowsDesktop
  }

  get title(): string | null {
    return this.data?.title || null
  }

  get subtitle(): string | null {
    return this.data?.subtitle || null
  }

  private hasTableData(): boolean {
    return Array.isArray(this.data?.tableData)
  }

  get tableData(): {
    key: string
    value: string
  }[] {
    if (!this.hasTableData()) {
      return []
    }

    return this.isMobileOrTablet()
      ? [...this.tableDataTopSection]
      : [...this.tableDataTopSection, ...this.tableDataBottomSection]
  }

  get tableDataTopSection(): {
    key: string
    value: string
  }[] {
    if (!this.hasTableData()) {
      return []
    }
    return this.data.tableData?.slice(0, this.numberOfMinTableRowsMobile) ?? []
  }

  get tableDataBottomSection(): {
    key: string
    value: string
  }[] {
    if (!this.hasTableData()) {
      return []
    }

    return this.data.tableData?.slice(this.numberOfMinTableRowsMobile) ?? []
  }

  get amountInvested(): number | null {
    return this.data?.amountInvested || null
  }

  get percentageInvested(): number | null {
    return this.data?.percentageInvested || null
  }

  toggleBottomSection() {
    this.bottomSectionVisible = !this.bottomSectionVisible
  }

  get showMoreIconClassNames(): string {
    const classes = ['card__show-more-icon']

    if (this.bottomSectionVisible) {
      classes.push('card__show-more-icon--rotated')
    }

    return classes.join(' ')
  }
}

export default Card
