












import { Component, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import RiskInformationInterface from '@exporo/financing_project_type_orm/dist/interfaces/Riskinformation'
import RiskInformation from '~/components/FixContent/RiskInformation/RiskInformation.vue'
import PostFundingProjects from '~/components/PostFunding/PostFundingProjects/PostFundingProjects.vue'
import Hero from '~/components/PostFunding/Hero.vue'

@Component({
  components: {
    Hero,
    RiskInformation,
    Container,
    PostFundingProjects
  }
})
export default class PagePostFunding extends Vue {
  private showRiskInformation: boolean = false
  private riskInformationData: RiskInformationInterface = {
    title: 'RISIKOHINWEIS',
    paragraph:
      'Der Erwerb dieser Wertpapiere ist mit erheblichen Risiken verbunden und kann zum vollständigen Verlust des eingesetzten Vermögens führen.'
  }

  private setShowRiskInformation(value: boolean): void {
    this.showRiskInformation = value
  }
}
