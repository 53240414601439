




















































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/types'
import PusherService from '~/components/PusherJS/PusherService'

@Component
class ProgressBar extends Vue {
  @Prop({ default: 0 })
  private readonly id!: number

  @Prop({ default: 0 })
  private readonly type!: string

  private percentageInvested: any = 0
  private amountInvested: any = 0

  private readonly thousandSeparator = '.'

  get formattedAmountInvested(): string | null {
    return this.amountInvested !== undefined
      ? this.amountInvested
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator)
      : null
  }

  mounted() {
    if (this.id && this.type) {
      PusherService.getInstance(
        (data: any) => {
          if (data) {
            this.onInvestmentUpdate(data)
          } else {
            Sentry.withScope((scope) => {
              scope.setExtra('messages', {
                data: PusherService.getAllData(),
                id: this.id,
                type: this.type
              })

              Sentry.captureMessage(
                `[PUSHER] onInvestmentUpdate failed`,
                Severity.Error
              )
            })
          }
        },
        this.id,
        this.type
      )
    }
  }

  onInvestmentUpdate(data: any) {
    this.percentageInvested = data.fundingInPercentage
    this.amountInvested = data.total_as_float
  }
}

export default ProgressBar
