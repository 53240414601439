












import { Component, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import RiskInformationInterface from '@exporo/financing_project_type_orm/dist/interfaces/Riskinformation'
import Hero from '~/components/Financing/Hero.vue'
import RiskInformation from '~/components/FixContent/RiskInformation/RiskInformation.vue'
import ActiveProjects from '~/components/Financing/ActiveProjects.vue'
import WhyFinancing from '~/components/Financing/WhyFinancing.vue'
const HowFinancing = () =>
  import('~/components/Financing/HowFinancing/HowFinancing.vue')

@Component({
  components: {
    Hero,
    RiskInformation,
    ActiveProjects,
    HowFinancing,
    WhyFinancing,
    Container
  }
})
class PageFinancing extends Vue {
  private showRiskInformation: boolean = true
  private riskInformationData: RiskInformationInterface = {
    title: 'RISIKOHINWEIS',
    paragraph:
      'Der Erwerb dieser Vermögensanlagen und Wertpapiere ist mit erheblichen Risiken verbunden und kann zum vollständigen Verlust des eingesetzten Vermögens führen.'
  }

  head() {
    return {
      script: [
        {
          type: 'text/javascript',
          src: 'https://try.abtasty.com/de7cb49a31fad42e9ed2d131bce24de0.js'
        }
      ]
    }
  }

  private setShowRiskInformation(value: boolean): void {
    this.showRiskInformation = value
  }
}

export default PageFinancing
