





































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import { StatusType } from '@exporo/financing_project_type_orm/dist/interfaces/Status'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import TrackingHelper from '~/components/Tracking/TrackingHelper'
import ProjectCardRow from '~/components/ProjectCard/ProjectCardRow.vue'
import temporalUrlFormatter from '~/components/temporalUrlFormatter'
import ProjectCardFoot from '~/components/ProjectCard/ProjectCardFoot.vue'
const ProgressBar = () => import('~/components/ProgressBar/ProgressBar.vue')

@Component({
  components: {
    Container,
    ProjectCardRow,
    ProgressBar,
    ProjectCardFoot
  }
})
class ProjectCard extends Vue {
  @Prop()
  data!: ProjectMetaData

  private trackingHelper: TrackingHelper

  constructor() {
    super()
    this.trackingHelper = TrackingHelper.getInstance({
      singleProjectMetaData: this.data
    })
  }

  imgSrc: string = this.data?.image
    ? `${temporalUrlFormatter(this.data.image)}?w=328`
    : 'https://exporo.imgix.net/uploads/20191220_Exporo_Stage.jpg'

  private goTo() {
    if (
      this.data.status === StatusType.COMING_SOON &&
      this.data.externalComingSoonUrl
    ) {
      location.replace(this.data.externalComingSoonUrl)
    } else if (
      this.data.status === StatusType.AFTER_FUNDING &&
      this.data.hash
    ) {
      this.$router.push(`/finanzierung/${this.data.slug}?pih=${this.data.hash}`)
    } else {
      this.$router.push(`/finanzierung/${this.data.slug}`)
    }
    this.trackingHelper.trackClick()
  }

  getBtnText(): string {
    return this.data.status === StatusType.COMING_SOON
      ? 'Jetzt vormerken'
      : 'Zum Projekt'
  }

  mounted() {
    this.trackingHelper.trackImpression()
  }
}

export default ProjectCard
