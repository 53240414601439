
































































import { Component, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import axios from 'axios'
import TeaserNumber from '~/components/Financing/TeaserNumber/TeaserNumber.vue'
import TeaserNumberInterface from '~/components/Financing/TeaserNumber/TeaserNumberInterface'
import ExporoCurrencyNumber from '~/components/ExporoCurrencyNumber/ExporoCurrencyNumber'

const BottomSheetButton = () =>
  import('~/components/BottomSheet/BottomSheetButton.vue')

@Component({
  components: {
    BottomSheetButton,
    TeaserNumber,
    Container
  }
})
export default class WhyFinancing extends Vue {
  private statNumbers: Array<TeaserNumberInterface> = []

  async requestStats() {
    try {
      const response = await axios.get(
        'https://kpis.dwh.exporo.de/home/kpis.json'
      )
      this.statNumbers = [
        {
          title: response.data.number_of_funded_financing_projects.data,
          subtitle: 'Projekte'
        },
        {
          title: new ExporoCurrencyNumber(
            response.data.intermediated_capital_financing.data
          ).toMillions(),
          subtitle: 'vermitteltes Kapital'
        },
        {
          title: new ExporoCurrencyNumber(
            response.data.total_return_payout_financing.data
          ).toMillions(),
          subtitle: 'zurückgezahltes Kapital'
        },
        {
          title: `${Math.round(
            response.data.average_effective_return_rate_financing.data * 100
          )} % p.A.`,
          subtitle: 'effektive Verzinsung'
        }
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  private openUrl(isStatistic: boolean) {
    if (isStatistic) {
      window.open('https://exporo.de/statistik/', '_self')
    } else {
      window.open('https://exporo.de/finanzierte-projekte', '_self')
    }
  }

  mounted(): void {
    this.requestStats()
  }
}
