































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import BottomSheetTopBarMainContent from '~/components/BottomSheet/BottomSheetTopBarMainContent.vue'

@Component({
  components: {
    Container,
    BottomSheetTopBarMainContent
  }
})
class BottomSheetTopBar extends Vue {
  @Prop()
  numberOfTabs!: number

  @Prop()
  tab!: number

  @Prop()
  title!: string

  onChange(event: any) {
    this.$emit('update:tab', event)
  }
}

export default BottomSheetTopBar
