





































import { Component, Provide, Vue } from 'nuxt-property-decorator'
import { Modules } from '@exporo/financing_project_type_orm/dist/interfaces/ToggleSectionModules/ModuleGroup'
import { store } from '~/store/Store'
import BottomSheetTabItems from '~/components/BottomSheet/BottomSheetTabItems.vue'
import BottomSheetTopBar from '~/components/BottomSheet/BottomSheetTopBar.vue'
import Module from '~/components/ToggleableContent/Module.vue'
import { Container } from '~/node_modules/@exporo/educts-component-grid'

@Component({
  components: {
    BottomSheetTabItems,
    BottomSheetTopBar,
    Module,
    Container
  }
})
class BottomSheet extends Vue {
  @Provide()
  private store = store

  private tab: number = 0

  get currentModulesLength(): number {
    return this.store.currentModules?.length === undefined
      ? 0
      : this.store.currentModules?.length
  }

  get firstCurrentModules(): Modules | undefined {
    return store.currentModules?.[0]
  }
}

export default BottomSheet
