



































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import MediaItem from 'node_modules/@exporo/financing_project_type_orm/dist/interfaces/MediaItem'

const VideoPlayer = () =>
  import(
    /* webpackChunkName: "VideoPlayer" */ '~/components/VideoPlayer/VideoPlayer.vue'
  )

@Component({
  components: {
    VideoPlayer
  }
})
export default class MediaContent extends Vue {
  @Prop()
  private data!: MediaItem[]

  @Prop({ default: '50px' })
  private width!: string

  @Prop({ default: '1920' })
  private maxWidth!: string

  @Prop({})
  private moduleGroupTitle!: string

  @Prop({ default: false })
  private stage!: boolean

  private mediaItem: MediaItem = this.data[0]

  private isMobile(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile'
  }
}
