
















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Modules } from '@exporo/financing_project_type_orm/dist/interfaces/ToggleSectionModules/ModuleGroup'
import Highchart from '~/components/Highchart/Highchart.vue'
import RichText from '~/components/RichText/RichText.vue'
import Carousel from '~/components/Carousel/Carousel.vue'
import ProConList from '~/components/ProConList/ProConList.vue'
import DownloadList from '~/components/DownloadList/DownloadList.vue'

const MapBox = () =>
  import(/* webpackChunkName: "MapBox" */ '~/components/MapBox/MapBox.vue')

@Component({
  components: {
    Highchart,
    RichText,
    MapBox,
    Carousel,
    ProConList,
    DownloadList
  }
})
class ExpansionPanel extends Vue {
  @Prop() title!: string
  @Prop() modules!: Modules
}

export default ExpansionPanel
