










































import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
class OfficeSpaceIcon extends Vue {
  @Prop({ default: 32 })
  private size!: number
}

export default OfficeSpaceIcon
