






































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'
import CTABtnBar from '~/components/CTABottomBar/CTABtnBar.vue'

const BottomSheet = () => import('~/components/BottomSheet/BottomSheet.vue')

const Stage = () =>
  import(
    /* webpackChunkName: "BaseTemplateResources" */ '~/components/Stage/Stage.vue'
  )

const FixContent = () =>
  import(
    /* webpackChunkName: "BaseTemplateResources" */ '~/components/FixContent/FixContent.vue'
  )

const ToggleSection = () =>
  import(
    /* webpackChunkName: "BaseTemplateResources" */ '~/components/ToggleableContent/ToggleSection.vue'
  )

@Component({
  components: {
    Stage,
    FixContent,
    ToggleSection,
    BottomSheet,
    CTABtnBar
  }
})
class BaseTemplate extends Vue {
  @Prop()
  private project!: ProjectMetaData

  @Prop()
  private projectData!: ProjectData | null

  private isMounted: boolean = false

  mounted() {
    this.isMounted = true
  }

  head() {
    return {
      bodyAttrs: {
        class: 'padding-body'
      }
    }
  }
}

export default BaseTemplate
