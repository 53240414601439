






import { Component, Vue, Prop } from 'nuxt-property-decorator'
import MediaItem from '~/components/Carousel/interfaces/MediaItem'
import MediaContent from '~/components/MediaContent/MediaContent.vue'

@Component({
  components: {
    MediaContent
  }
})
export default class Slide extends Vue {
  @Prop()
  private mediaItem!: MediaItem

  @Prop({ default: false })
  private stage!: boolean
}
