











import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
class CtaButtonSkeleton extends Vue {
  @Prop({ default: '#3968af' })
  private readonly color!: string

  @Prop({ default: '100%' })
  private readonly width!: string
}

export default CtaButtonSkeleton
