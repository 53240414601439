







import { Component, Prop, Vue } from 'nuxt-property-decorator'
import RiskInformationInterface from '@exporo/financing_project_type_orm/dist/interfaces/Riskinformation'

@Component
class RiskInformation extends Vue {
  @Prop()
  data!: RiskInformationInterface
}

export default RiskInformation
