













import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  components: {}
})
export default class BookmarkedContent extends Vue {
  @Prop()
  private date!: any

  @Prop()
  private campaignHelper!: boolean
}
