

























































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { breakpoint } from '@exporo/educts-styleguide'
import MediaItem from '~/components/Carousel/interfaces/MediaItem'
import Carousel from '~/components/Carousel/Carousel.vue'
import MediaDialogEventManager from '~/components/MediaDialog/MediaDialogEventManager'

const ProgressiveImage = () =>
  import(
    /* webpackChunkName: "ProgressiveImage" */ '~/components/ProgressiveImage/ProgressiveImage.vue'
  )

@Component({
  components: { ProgressiveImage, Carousel }
})
export default class MediaDialog extends Vue {
  @Prop({ default: 'large' })
  private buttonType!: 'x-small' | 'small' | 'large' | 'x-large'

  @Prop({ default: '' })
  private buttonSize!: string

  @Prop({ default: 'mdi-image-multiple-outline' })
  private buttonIcon!: string

  @Prop({ default: 'See Gallery' })
  private buttonText!: string

  @Prop({ default: 'rgba(255, 255, 255, 1)' })
  private buttonColor!: string

  @Prop({ default: null })
  private interval!: number | null

  @Prop({ required: true })
  private mediaItems!: MediaItem[]

  @Prop({ default: `${breakpoint.large}px` })
  private maxWidth!: string

  private open: boolean = false

  closeDialog(): void {
    MediaDialogEventManager.notify()
    this.open = false
  }

  isMobileOrTablet(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile' || mediaQuery === 'tablet'
  }
}
