








import { Component, Vue, Prop } from 'nuxt-property-decorator'
import DownloadListData from '~/components/DownloadList/DownloadListData'
import DownloadBtn from '~/components/DownloadBtn/DownloadBtn.vue'

@Component({
  components: {
    DownloadBtn
  }
})
class DownloadList extends Vue {
  @Prop() data!: DownloadListData[]
}

export default DownloadList
