




import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import QuoteboxInterface from '@exporo/financing_project_type_orm/dist/interfaces/Quotebox'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'
import QuoteBox from './QuoteBox.vue'
import QuoteBoxSkeleton from './QuoteBoxSkeleton.vue'

@Component({
  components: { QuoteBox, QuoteBoxSkeleton }
})
class QuoteBoxContainer extends Vue {
  @Prop()
  private projectData!: ProjectData | null

  private data: QuoteboxInterface | null =
    this.projectData?.data?.fixContent?.quotebox || null

  @Watch('projectData')
  private dataChanged(
    newData: ProjectData
    // oldData: ProjectData
  ) {
    this.data = newData?.data?.fixContent?.quotebox || null
  }
}

export default QuoteBoxContainer
