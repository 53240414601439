import { render, staticRenderFns } from "./BottomSheetTopBar.vue?vue&type=template&id=cc39e56c&scoped=true&"
import script from "./BottomSheetTopBar.vue?vue&type=script&lang=ts&"
export * from "./BottomSheetTopBar.vue?vue&type=script&lang=ts&"
import style0 from "./BottomSheetTopBar.vue?vue&type=style&index=0&id=cc39e56c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc39e56c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAppBar,VTab,VTabs})
