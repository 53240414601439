
























import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Location extends Vue {
  @Prop({ default: 32 })
  private size!: number
}
