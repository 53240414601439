




import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IconType } from '@exporo/financing_project_type_orm/dist/interfaces/Icon'
import CommercialSpaceIcon from '~/components/SvgComponents/Icons/CommercialSpaceIcon.vue'
import CommercialUnitIcon from '~/components/SvgComponents/Icons/CommercialUnitIcon.vue'
import LivingSpaceIcon from '~/components/SvgComponents/Icons/LivingSpaceIcon.vue'
import LivingUnitIcon from '~/components/SvgComponents/Icons/LivingUnitIcon.vue'
import OfficeSpaceIcon from '~/components/SvgComponents/Icons/OfficeSpaceIcon.vue'
import OfficeUnitIcon from '~/components/SvgComponents/Icons/OfficeUnitIcon.vue'
import ProjectRevenueIcon from '~/components/SvgComponents/Icons/ProjectRevenueIcon.vue'
import ProjectTypeIcon from '~/components/SvgComponents/Icons/ProjectTypeIcon.vue'
import PropertySizeIcon from '~/components/SvgComponents/Icons/PropertySizeIcon.vue'
import TotalUsableSpaceIcon from '~/components/SvgComponents/Icons/TotalUsableSpaceIcon.vue'
import TypeOfUseIcon from '~/components/SvgComponents/Icons/TypeOfUseIcon.vue'
import CapitalDistributionIcon from '~/components/SvgComponents/Icons/CapitalDistributionIcon.vue'
import ProjectDevExpIcon from '~/components/SvgComponents/Icons/ProjectDevExpIcon.vue'
import ProjectStatusIcon from '~/components/SvgComponents/Icons/ProjectStatusIcon.vue'
import RiskReductionIcon from '~/components/SvgComponents/Icons/RiskReductionIcon.vue'
import SaleStatusIcon from '~/components/SvgComponents/Icons/SaleStatusIcon.vue'
import LocationIcon from '~/components/SvgComponents/Icons/LocationIcon.vue'
import LocationHollowIcon from '~/components/SvgComponents/Icons/LocationHollowIcon.vue'
import NeighborhoodIcon from '~/components/SvgComponents/Icons/NeighborhoodIcon.vue'
import ConnectionIcon from '~/components/SvgComponents/Icons/ConnectionIcon.vue'
import PopulationIcon from '~/components/SvgComponents/Icons/PopulationIcon.vue'

@Component({
  components: {
    CommercialSpaceIcon,
    CommercialUnitIcon,
    LivingSpaceIcon,
    LivingUnitIcon,
    OfficeSpaceIcon,
    OfficeUnitIcon,
    ProjectRevenueIcon,
    ProjectTypeIcon,
    PropertySizeIcon,
    TotalUsableSpaceIcon,
    TypeOfUseIcon,
    CapitalDistributionIcon,
    LocationIcon,
    ProjectDevExpIcon,
    ProjectStatusIcon,
    RiskReductionIcon,
    SaleStatusIcon,
    LocationHollowIcon,
    NeighborhoodIcon,
    ConnectionIcon,
    PopulationIcon
  }
})
class Icon extends Vue {
  @Prop({ default: IconType.LIVING_SPACE })
  private type!: IconType

  @Prop({ default: 32 })
  private size!: number
}

export default Icon
