












import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
class ProgressiveImage extends Vue {
  @Prop({ default: '' })
  private type!: string

  @Prop({ default: true })
  private cover!: boolean

  @Prop()
  private src!: string

  @Prop()
  private srcSmall?: string

  @Prop({ default: '' })
  private alt!: string

  private progressiveImage!: HTMLLinkElement
  private timer!: null | NodeJS.Timeout
  private loaded: boolean = false

  get smallSrc(): string {
    return this.srcSmall || `${this.src}?w=27`
  }

  get width() {
    const mediaQuery = (this as any).$mq

    if (mediaQuery === 'mobile') {
      return '500'
    } else if (mediaQuery === 'tablet') {
      return '1024'
    }

    return '1920'
  }

  mounted(): void {
    // @TODO find a way to detect parent is full mounted and rendered
    setTimeout(() => {
      this.init()
    }, 300)
  }

  init(): void {
    this.progressiveImage = this.$refs.progressive as HTMLLinkElement

    if (this.progressiveImage) {
      window.addEventListener('scroll', this.scroller.bind(this), {
        passive: true,
        capture: false
      })
      window.addEventListener('resize', this.scroller.bind(this), {
        passive: true,
        capture: false
      })
      this.inView()
    }
  }

  inView(): void {
    if (this.loaded) {
      return
    }

    const progressiveWrapper = this.$refs.progressiveWrapper as Element
    const pageYOffset = window.pageYOffset
    const windowBottom = pageYOffset + window.innerHeight
    const cRect = progressiveWrapper.getBoundingClientRect()
    const pageTop = pageYOffset + cRect.top
    const pageBottom = pageTop + cRect.height

    if (pageYOffset < pageBottom && windowBottom > pageTop) {
      this.loadFullImage()
      this.progressiveImage.classList.remove('replace')
    }
  }

  loadFullImage(): void {
    if (!this.progressiveImage || !this.progressiveImage.href) {
      return
    }

    const image = new Image()

    if (this.progressiveImage.dataset) {
      image.srcset = this.progressiveImage.dataset.srcset || ''
      image.sizes = this.progressiveImage.dataset.sizes || ''
    }

    image.src = this.progressiveImage.href
    image.className = 'reveal'

    if (image.complete) {
      this.addImage(image)
      return
    }

    image.onload = () => this.addImage(image)
  }

  addImage(image: HTMLImageElement): void {
    this.progressiveImage.addEventListener(
      'click',
      (e: any) => {
        e.preventDefault()
      },
      {
        passive: true,
        capture: false
      }
    )

    const imageFull: HTMLImageElement = this.progressiveImage.querySelector(
      'img.reveal'
    ) as HTMLImageElement

    if (imageFull) {
      return
    }

    const imagePreview: HTMLImageElement = this.progressiveImage.querySelector(
      'img.preview'
    ) as HTMLImageElement

    this.progressiveImage.appendChild(image)

    if (imagePreview) {
      imagePreview.style.height = `${image.height}px`
    }

    this.progressiveImage.addEventListener(
      'animationend',
      (e: any) => {
        if (!imagePreview) {
          return
        }

        e.target.alt = imagePreview.alt || ''
        this.progressiveImage.removeChild(imagePreview)
        e.target.classList.remove('reveal')
        this.loaded = true
      },
      {
        passive: true,
        capture: false
      }
    )
  }

  scroller() {
    this.timer =
      this.timer ||
      setTimeout(() => {
        this.timer = null
        requestAnimationFrame(this.inView)
      }, 300)
  }
}

export default ProgressiveImage
