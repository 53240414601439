























































































import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import CardData from '@exporo/financing_project_type_orm/dist/interfaces/CardData'
import StageInterface from '@exporo/financing_project_type_orm/dist/interfaces/StageInterface'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'
import MediaItem, {
  MediaItemVideoProvider
} from '~/components/Carousel/interfaces/MediaItem'
import { TYPE_BOND, TYPE_PROJECT } from '~/components/PusherJS/PusherService'
import Card from '~/components/Card/Card.vue'
import MediaDialog from '~/components/MediaDialog/MediaDialog.vue'

@Component({
  components: {
    Card,
    MediaDialog
  }
})
class Stage extends Vue {
  @Prop()
  private project!: ProjectMetaData

  @Prop()
  private projectData!: ProjectData | null

  @Prop({ default: '25em' })
  private readonly maxHeightMobile!: string

  @Prop({ default: '40em' })
  private readonly maxHeightDesktop!: string

  @Prop()
  private readonly template!: string

  private data: StageInterface | null = this.projectData?.data?.stage || null

  get width() {
    const mediaQuery = (this as any).$mq

    if (mediaQuery === 'mobile') {
      return '500'
    } else if (mediaQuery === 'tablet') {
      return '1024'
    }

    return '1920'
  }

  // @TODO: should be replaces by project.type
  getType() {
    let isLoan = true

    if (this.cardData && this.cardData.financingCheckoutSlug) {
      isLoan = this.cardData.financingCheckoutSlug.includes('/loan/')
    }

    return isLoan ? TYPE_PROJECT : TYPE_BOND
  }

  @Watch('projectData')
  private dataChanged(
    newData: ProjectData
    // @todo check if oldData can be deleted
    // oldData: ProjectData
  ) {
    this.data = newData?.data?.stage || null
  }

  private isMobileOrTablet(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile' || mediaQuery === 'tablet'
  }

  private get fabSize(): number {
    return this.isMobileOrTablet() ? 40 : 64
  }

  private get maxHeight(): string {
    return this.isMobileOrTablet()
      ? this.maxHeightMobile
      : this.maxHeightDesktop
  }

  get cardData(): CardData | null {
    return this.data?.cardData || null
  }

  loadsMediaItems(): boolean {
    return this.data === null || this.data.mediaItems === undefined
  }

  get mediaItems(): MediaItem[] | null {
    return this.data !== null &&
      this.data.mediaItems &&
      this.data.mediaItems.length > 0
      ? this.data.mediaItems
      : null
  }

  loadsVideoId(): boolean {
    return this.data === null || this.data.videoId === undefined
  }

  get videoId(): string | null {
    return this.data?.videoId || null
  }

  get videoProvider(): MediaItemVideoProvider | null {
    return this.data?.videoProvider || null
  }

  get videoMediaItem(): MediaItem | null {
    const provider = this.videoProvider
    const src = this.videoId

    if (!provider || !src) {
      return null
    }

    return { provider, src }
  }

  hasMediaItems(): boolean {
    if (!this.data || !this.data.mediaItems) {
      return false
    }

    return this.data.mediaItems.length > 0
  }
}

export default Stage
