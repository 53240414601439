









import { Component, Prop, Provide, Vue } from 'nuxt-property-decorator'
import { StatusType } from '@exporo/financing_project_type_orm/dist/interfaces/Status'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'
import axios, { AxiosResponse } from 'axios'
import { mutations, Store, store } from '~/store/Store'
import TrackingHelper from '~/components/Tracking/TrackingHelper'

const BaseTemplate = () =>
  import(
    /* webpackChunkName: "BaseTemplate" */ '~/components/ProjectDetailTemplate/StatusTemplates/BaseTemplate/BaseTemplate.vue'
  )

const RepaidTemplate = () =>
  import(
    /* webpackChunkName: "RepaidTemplate" */ '~/components/ProjectDetailTemplate/StatusTemplates/RepaidTemplate/RepaidTemplate.vue'
  )

const ComingSoonTemplate = () =>
  import(
    /* webpackChunkName: "ComingSoonTemplate" */ '~/components/ProjectDetailTemplate/StatusTemplates/ComingSoonTemplate/ComingSoonTemplate.vue'
  )

@Component({
  components: {
    BaseTemplate,
    RepaidTemplate,
    ComingSoonTemplate
  }
})
class ProjectDetailTemplate extends Vue {
  @Provide()
  private store: Store = store

  private project: ProjectMetaData | null = null

  @Prop()
  private slug!: string

  private projectData: ProjectData | null = null

  private readonly defaultStatusType: StatusType | null = StatusType.IN_FUNDING
  private readonly defaultStatusTemplateName: string = 'BaseTemplate'

  head() {
    return {
      title: this.project?.title ?? 'Exporo Finanzierung',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Projekt Detail Seite | ${this.project?.title ??
            'Exporo Finanzierung'}`
        }
      ]
    }
  }

  pushToGTM() {
    if (this.project) {
      TrackingHelper.getInstance({
        singleProjectMetaData: this.project,
        singleProjectData: this.projectData
      }).trackProductDetail()
    }
  }

  async beforeMount() {
    this.changeStatusType()
    await this.loadProjectData()
  }

  private async loadProjectData(): Promise<void> {
    if (this.slug) {
      this.project = ((await axios
        .get(`${process.env.backendApiBaseUrl}/v1/projects/meta/${this.slug}`)
        .catch((error: any) => {
          throw new Error(`API ${error}`)
        })) as AxiosResponse).data

      mutations.setLegalType((this.project as any).investmentType)

      try {
        this.projectData = await this.$axios.$get(
          `${process.env.backendApiBaseUrl}/v1/projects/${this.slug}`
        )

        this.pushToGTM()

        // Remote status may differ from local data or query param value
        if (this.projectData?.statusType !== store.currentProjectStatusType) {
          this.changeStatusType()
        }
      } catch (error) {
        // console.log(error)
      }
    }
  }

  beforeDestroy() {
    this.setCurrentProjectStatusTypeState(null)
  }

  private setCurrentProjectStatusTypeState(statusType: StatusType | null) {
    mutations.setCurrentProjectStatusType(statusType)
  }

  private changeStatusType(): void {
    const queryParam: string | (string | null)[] = this.$route.query.status
    const correctQueryParamValue = queryParam && typeof queryParam === 'string'

    // Remove query parameter `status` when data was successfully loaded
    if (this.projectData?.statusType && correctQueryParamValue) {
      this.$router.replace({ query: { status: undefined } })
    }

    // Set status of remote project data, when available
    if (this.project && this.projectData?.statusType) {
      if (
        this.project.investable &&
        this.$route.query.pih === this.project.hash
      ) {
        this.setCurrentProjectStatusTypeState(StatusType.IN_FUNDING)
      } else {
        this.setCurrentProjectStatusTypeState(this.projectData.statusType)
      }
      return
    }

    // If no query parameter is present, fallback to `defaultStatusType`
    if (!correctQueryParamValue) {
      this.setCurrentProjectStatusTypeState(this.defaultStatusType)
      return
    }

    // Set status by correct query param value
    switch (queryParam) {
      case 'start':
        this.setCurrentProjectStatusTypeState(StatusType.START)
        return
      case 'coming-soon':
        this.setCurrentProjectStatusTypeState(StatusType.COMING_SOON)
        return
      case 'in-funding':
        this.setCurrentProjectStatusTypeState(StatusType.IN_FUNDING)
        return
      case 'funded':
        this.setCurrentProjectStatusTypeState(StatusType.FUNDED)
        return
      case 'after-funding':
        this.setCurrentProjectStatusTypeState(StatusType.AFTER_FUNDING)
        return
      case 'repaid':
        this.setCurrentProjectStatusTypeState(StatusType.REPAID)
        return
      default:
        this.setCurrentProjectStatusTypeState(this.defaultStatusType)
    }
  }

  get statusTemplateName(): string {
    switch (store.currentProjectStatusType) {
      case StatusType.PRE_FUNDING:
      case StatusType.COMING_SOON:
        return 'ComingSoonTemplate'
      case StatusType.START:
      case StatusType.IN_FUNDING:
        return 'BaseTemplate'
      case StatusType.FUNDED:
      case StatusType.AFTER_FUNDING:
      case StatusType.REPAID:
        return 'RepaidTemplate'
      default:
        return this.defaultStatusTemplateName
    }
  }
}

export default ProjectDetailTemplate
