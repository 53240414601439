













import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'
import RiskinformationInterface from '@exporo/financing_project_type_orm/dist/interfaces/Riskinformation'
import RiskInformationSkeleton from '~/components/FixContent/RiskInformation/RiskInformationSkeleton.vue'
import RiskInformation from '~/components/FixContent/RiskInformation/RiskInformation.vue'

@Component({
  components: {
    RiskInformation,
    RiskInformationSkeleton,
    Container
  }
})
class RiskInformationContainer extends Vue {
  @Prop()
  private projectData!: ProjectData | null

  private data: RiskinformationInterface | null =
    this.projectData?.data?.fixContent?.riskinformation || null

  @Watch('projectData')
  private dataChanged(
    newData: ProjectData
    // oldData: ProjectData
  ) {
    this.data = newData?.data?.fixContent?.riskinformation || null
  }
}

export default RiskInformationContainer
