

























import { Component, Vue } from 'nuxt-property-decorator'
import axios from 'axios'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import { Container } from '~/node_modules/@exporo/educts-component-grid'
import ProjectCard from '~/components/ProjectCard/ProjectCard.vue'

@Component({
  components: {
    Container,
    ProjectCard
  }
})
export default class PostFundingProjects extends Vue {
  private projects: ProjectMetaData[] | null = null

  async setProjects() {
    try {
      const response = await axios.get(
        `${process.env.backendApiBaseUrl}/v1/projects/meta/postfunding`
      )
      this.projects = response.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  async mounted() {
    await this.setProjects()
  }
}
