










import { Component, Prop, Provide, Vue } from 'nuxt-property-decorator'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import RichTextData from '~/components/RichText/RichTextData'

@Component
class RichText extends Vue {
  @Prop() data!: RichTextData
  @Prop() isComingSoon!: boolean
  @Provide() documentToHtmlString = documentToHtmlString
}

export default RichText
