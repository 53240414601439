









import { Component, Prop, Vue } from 'vue-property-decorator'
import MediaItem from '~/components/Carousel/interfaces/MediaItem'

@Component
export default class Logo extends Vue {
  @Prop()
  private data!: MediaItem[]
}
