






import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  components: {}
})
class RiskInformationSkeleton extends Vue {}

export default RiskInformationSkeleton
