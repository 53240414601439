














import { Component, Prop, Vue } from 'nuxt-property-decorator'
import * as contentful from 'contentful'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import RichText from '~/components/RichText/RichText.vue'
import StatusButton from '~/components/ProjectDetailTemplate/StatusTemplates/ComingSoonTemplate/StatusButton.vue'

@Component({
  components: {
    RichText,
    StatusButton
  }
})
export default class NotBookmarkedContent extends Vue {
  @Prop()
  private richTextData!: contentful.EntryFields.RichText | null

  @Prop()
  private isBiggerThanTablet!: boolean

  @Prop()
  private project!: ProjectMetaData
}
