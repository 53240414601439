






















import { Component, Vue } from 'nuxt-property-decorator'

@Component
class KeyfactsSkeleton extends Vue {}

export default KeyfactsSkeleton
