


























import { Component, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import Process from '~/components/Financing/HowFinancing/Process.vue'
import WistiaVideo from '~/components/WistiaVideo/WistiaVideo.vue'

@Component({
  components: {
    Process,
    Container,
    WistiaVideo
  }
})
export default class HowFinancing extends Vue {}
