













import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'

@Component({
  components: {
    Container
  }
})
export default class BottomSheetTopBarMainContent extends Vue {
  @Prop()
  title!: string

  closeBottomSheet() {
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    )
    this.$router.go(-2)
  }
}
