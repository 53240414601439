











import { Component, Prop, Vue } from 'nuxt-property-decorator'
import RichText from '~/components/RichText/RichText.vue'
import RichTextData from '~/components/RichText/RichTextData'

@Component({
  components: {
    RichText
  }
})
class Keyfacts extends Vue {
  @Prop({ required: true })
  private readonly data!: RichTextData
}

export default Keyfacts
