










































import { Component, Vue } from 'nuxt-property-decorator'
import FinancingLogo from '~/components/SvgComponents/Icons/FinancingLogoIcon.vue'

@Component({
  components: {
    FinancingLogo
  }
})
export default class EmptyState extends Vue {}
