









































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import ExporoClass from '~/components/ProjectDetailTemplate/StatusTemplates/ComingSoonTemplate/ExporoClass.vue'

@Component({
  components: {
    ExporoClass
  }
})
export default class ComingSoonFacts extends Vue {
  @Prop()
  project!: ProjectMetaData

  @Prop()
  isBiggerThanTablet!: boolean
}
