



































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'
import { UserEvents } from '@exporo/educts-component-user'
import * as contentful from 'contentful'
import List from '~/components/ReusableComponents/List.vue'
import ExporoClass from '~/components/ProjectDetailTemplate/StatusTemplates/ComingSoonTemplate/ExporoClass.vue'
import StatusButton from '~/components/ProjectDetailTemplate/StatusTemplates/ComingSoonTemplate/StatusButton.vue'
import ShareBtn from '~/components/ShareBtn/ShareBtn.vue'
import CampaignHelper from '~/components/Helper/CampaignHelper'
import RichText from '~/components/RichText/RichText.vue'
import NotBookmarkedContent from '~/components/ProjectDetailTemplate/StatusTemplates/ComingSoonTemplate/Bookmark/NotBookmarkedContent.vue'
import BookmarkedContent from '~/components/ProjectDetailTemplate/StatusTemplates/ComingSoonTemplate/Bookmark/BookmarkedContent.vue'
import ComingSoonHead from '~/components/ProjectDetailTemplate/StatusTemplates/ComingSoonTemplate/Head/ComingSoonHead.vue'
import ComingSoonFacts from '~/components/ProjectDetailTemplate/StatusTemplates/ComingSoonTemplate/Facts/ComingSoonFacts.vue'

@Component({
  components: {
    BookmarkedContent,
    ComingSoonFacts,
    List,
    Container,
    ExporoClass,
    StatusButton,
    ShareBtn,
    RichText,
    NotBookmarkedContent,
    ComingSoonHead
  }
})
export default class ComingSoonTemplate extends Vue {
  @Prop()
  private project!: ProjectMetaData

  @Prop()
  private projectData!: ProjectData | null

  private date: string | null = null
  private campaignHelper: CampaignHelper | null = null

  private richTextData: contentful.EntryFields.RichText | null =
    (this.projectData?.data?.fixContent
      ?.keyfacts as contentful.EntryFields.RichText) || null

  mounted() {
    this.$user.on(UserEvents.StateChanged, () => {
      this.$forceUpdate()
    })
  }

  created() {
    this.campaignHelper = new CampaignHelper(
      this.$route,
      this.project.financingEntityId
    )

    this.date =
      this.campaignHelper.getCampaignDate(this.project.campaigns) ??
      this.project.startDate
  }

  get backgroundImageUrl(): string | null {
    return `${this.project.image}?w=1920`
  }

  isBiggerThanTablet(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery !== 'mobile' && mediaQuery !== 'tablet'
  }
}
