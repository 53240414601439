





import { Component, Vue } from 'nuxt-property-decorator'
import ContactboxInterface from '@exporo/financing_project_type_orm/dist/interfaces/Contactbox'
import ContactBox from '~/components/FixContent/ContactBox/ContactBox.vue'
import ContactBoxSkeleton from '~/components/FixContent/ContactBox/ContactBoxSkeleton.vue'

@Component({
  components: {
    ContactBox,
    ContactBoxSkeleton
  }
})
class ContactBoxContainer extends Vue {
  get contactBoxData(): ContactboxInterface {
    return {
      title: 'Haben Sie Fragen?',
      subTitle: 'Kontaktieren Sie unsere Experten',
      contactPersonName: 'Frank Schulze',
      contactPersonJobTitle: 'Kundenservice',
      contactPhone: '+49 (0) 40 228 68 69 90',
      contactEmail: 'info@exporo.com',
      contactPersonPicUrl: '~/assets/financing_pdp_contact.jpg'
    }
  }
}

export default ContactBoxContainer
