














import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import Highchart from '~/components/Highchart/Highchart.vue'
import RichText from '~/components/RichText/RichText.vue'
import ProConList from '~/components/ProConList/ProConList.vue'
import DownloadList from '~/components/DownloadList/DownloadList.vue'
import Carousel from '~/components/Carousel/Carousel.vue'
import IconGroup from '~/components/IconGroup/IconGroup.vue'
import BubbleGroup from '~/components/BubbleRating/BubbleGroup.vue'
import Logo from '~/components/Logo/Logo.vue'
import Title from '~/components/Title/Title.vue'
import MediaContent from '~/components/MediaContent/MediaContent.vue'

const MapBox = () =>
  import(/* webpackChunkName: "MapBox" */ '~/components/MapBox/MapBox.vue')

@Component({
  components: {
    Highchart,
    RichText,
    MapBox,
    Carousel,
    ProConList,
    DownloadList,
    IconGroup,
    BubbleGroup,
    Container,
    Logo,
    Title,
    MediaContent
  }
})
class Module extends Vue {
  @Prop({}) module!: { type: string; data: any }
  @Prop() title!: string

  private shouldLoadModule: boolean = false
}

export default Module
