

















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { UserEvents } from '@exporo/educts-component-user'
import CtaButton from '~/components/CtaButton/CtaButton.vue'
import DownloadBtn from '~/components/DownloadBtn/DownloadBtn.vue'
import CampaignHelper from '~/components/Helper/CampaignHelper'

@Component({
  components: {
    CtaButton,
    DownloadBtn
  }
})
export default class StatusButton extends Vue {
  @Prop()
  private projectEntityId!: string

  @Prop()
  private exposeUrl!: string

  @Prop()
  private campaignHelper!: CampaignHelper

  async mounted() {
    this.$user.on(UserEvents.StateChanged, () => {
      this.$forceUpdate()
    })

    if (this.campaignHelper.isNoted && this.$user.isAuthenticated) {
      await this.campaignHelper.sendCampaign()
    }
  }

  protected async routeToLogin(): Promise<void> {
    await this.noteProject()
    document.location.href =
      'https://user.exporo.com/#/signin?redirectURL=' + window.location.href
  }

  private async noteProject(): Promise<void> {
    this.campaignHelper.setNotedProject()
    await this.campaignHelper.sendCampaign()
  }
}
