










import { Vue, Component } from 'nuxt-property-decorator'

@Component
class Index extends Vue {
  head() {
    return {
      script: [
        {
          type: 'text/javascript',
          src: 'https://try.abtasty.com/de7cb49a31fad42e9ed2d131bce24de0.js'
        }
      ]
    }
  }
}

export default Index
