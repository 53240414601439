import { render, staticRenderFns } from "./ModuleGroup.vue?vue&type=template&id=bf42089c&scoped=true&"
import script from "./ModuleGroup.vue?vue&type=script&lang=ts&"
export * from "./ModuleGroup.vue?vue&type=script&lang=ts&"
import style0 from "./ModuleGroup.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ModuleGroup.vue?vue&type=style&index=1&id=bf42089c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf42089c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VRow})
