










import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'
import CardData from '@exporo/financing_project_type_orm/dist/interfaces/CardData'
const CtaButton = () => import('~/components/CtaButton/CtaButton.vue')

@Component({
  components: {
    CtaButton
  }
})
class CTABtnBar extends Vue {
  private show: boolean = false
  private isMounted: boolean = false
  @Prop()
  private projectData!: ProjectData | null

  private cardData: CardData | null | undefined = null
  private toggleBar(): void {
    this.show = !this.show
  }

  private addEventListener(): void {
    this.$root.$on('toggle-cta-btn-bar', () => {
      this.toggleBar()
    })
  }

  private checkoutUrl(): string {
    return this.cardData?.financingCheckoutSlug
      ? this.cardData?.financingCheckoutSlug +
          '?referrer=' +
          window.location.href
      : ''
  }

  mounted() {
    this.addEventListener()
    this.cardData = this.projectData?.data?.stage?.cardData
    this.isMounted = true
  }
}

export default CTABtnBar
