



















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IconGroupType from '@exporo/financing_project_type_orm/dist/interfaces/ToggleSectionModules/IconGroup'
import Icon from '~/components/Icon/Icon.vue'
import ExporoClassIcon from '~/components/IconGroup/Icon/ExporoClassIcon.vue'

@Component({
  components: { Icon, ExporoClassIcon }
})
export default class ExporoClassIcons extends Vue {
  @Prop()
  private data!: IconGroupType[]

  @Prop()
  private moduleGroupTitle!: string

  get criteriaIcons() {
    return this.data.filter((criteriaIcon: any) => {
      return criteriaIcon.icon && criteriaIcon.title
    })
  }

  private isMobile(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile'
  }

  get totalPoints() {
    if (this.moduleGroupTitle === 'Exporo Klasse')
      return this.data[this.data.length - 1]
    else return null
  }
}
