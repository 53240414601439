

























import { Component, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import FinancingLogo from '~/components/SvgComponents/Icons/FinancingLogoIcon.vue'

@Component({
  components: {
    FinancingLogo,
    Container
  }
})
class Hero extends Vue {
  private backgroundImageUrl: string = ''

  isMobileOrTablet(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile' || mediaQuery === 'tablet'
  }

  beforeMount(): void {
    this.backgroundImageUrl = `https://s3.eu-central-1.amazonaws.com/media.financing.exporo.io/GFK_Headerbild.jpg?w=${window.innerWidth}`
  }
}

export default Hero
