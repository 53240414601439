













import { Component, Provide, Vue } from 'nuxt-property-decorator'
import { Educts, Region } from '@exporo/educts'
import { store } from '~/store/Store'

declare global {
  interface Window {
    _abtasty: any
    abtiming: any
    dataLayer: any
  }
}

@Component
class DefaultLayout extends Vue {
  @Provide()
  private store = store

  appType: Educts.Brand = Educts.Brand.Financing
  region: Region = Region.Germany

  mounted() {
    setTimeout(() => {
      window._abtasty = (window as any)._abtasty || []
      window.abtiming = +new Date()

      const script: any = document.createElement('script')
      script.async = ''
      script.type = 'text/plain'
      script.dataset.usercentrics = 'AB Tasty'
      script.src = '//try.abtasty.com/de7cb49a31fad42e9ed2d131bce24de0.js'

      const wrapper: any = document.getElementsByTagName('script')[0]
      if (wrapper) {
        wrapper.parentNode.insertBefore(script, wrapper)
      }

      const header = document.getElementsByTagName('header')[0]
      let isUserLoggedIn: boolean = false
      if (header) {
        const links = header.getElementsByTagName('a')
        for (const link of links as any) {
          const href = (link as any).href
          /* eslint-disable */
          if (href && href.indexOf('https://cockpit.exporo.de') >= 0) {
            isUserLoggedIn = true
          }
          /* eslint-enable */
        }
      }
      if (isUserLoggedIn) {
        window.dataLayer.push({
          appId: 'financing_exporo_de',
          user: {
            // @ts-ignore
            userId: this.$user.metaData?.id,
            a_aid: 0,
            loginState: this.$user.isAuthenticated
          }
        })
      }
    }, 5000)
  }
}

export default DefaultLayout
