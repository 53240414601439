




















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
class WistiaVideo extends Vue {
  @Prop()
  videoId!: string

  jsonPLoaded: boolean = false
  externalScript: boolean = false

  head() {
    return {
      script: [
        {
          src: `https://fast.wistia.com/embed/medias/${this.videoId}.jsonp`,
          callback: () => {
            this.jsonPLoaded = true
          }
        },
        {
          src: 'https://fast.wistia.com/assets/external/E-v1.js',
          callback: () => {
            this.externalScript = true
          }
        }
      ]
    }
  }
}

export default WistiaVideo
