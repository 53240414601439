





























































































































































































































































import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class FinancingLogo extends Vue {
  @Prop({ default: 200 })
  private size!: number
}
