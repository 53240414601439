





















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'

@Component({
  components: {}
})
export default class ComingSoonHead extends Vue {
  @Prop()
  project!: ProjectMetaData
}
