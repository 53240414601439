














import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import Bubble from '~/components/BubbleRating/Bubble.vue'

@Component({
  components: {
    Container,
    Bubble
  }
})
export default class ExporoClassFull extends Vue {
  @Prop()
  private exporoClass!: string

  @Prop({ default: undefined })
  private text!: boolean

  @Prop({ default: true })
  private center!: boolean

  private bubbles: { value: string; title: string; color: string }[] = [
    { value: 'AA', title: '<7 Punkte', color: '#B0BEC5' },
    { value: 'A', title: '7-9 Punkte', color: '#274a7f' },
    { value: 'B', title: '10-12 Punkte', color: '#1e73be' },
    { value: 'C', title: '13-15 Punkte', color: '#4CAF50' },
    { value: 'D', title: '16-18 Punkte', color: '#CDDC39' },
    { value: 'E', title: '19-21 Punkte', color: '#FFEB3B' },
    { value: 'F', title: '>21 Punkte', color: '#FF9800' }
  ]
}
