











import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import TeaserNumberInterface from '~/components/Financing/TeaserNumber/TeaserNumberInterface'

@Component({
  components: {
    Container
  }
})
class TeaserNumber extends Vue {
  @Prop({ default: null })
  private readonly data!: TeaserNumberInterface | null
}

export default TeaserNumber
