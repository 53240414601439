















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Modules } from '@exporo/financing_project_type_orm/dist/interfaces/ToggleSectionModules/ModuleGroup'
import Module from '~/components/ToggleableContent/Module.vue'
import { Container } from '~/node_modules/@exporo/educts-component-grid'

@Component({
  components: {
    Module,
    Container
  }
})
class BottomSheetTabItems extends Vue {
  @Prop() currentModules!: Modules[]
  @Prop() tab!: number
  @Prop() title!: string

  onChange(event: any) {
    this.$emit('update:tab', event)
  }
}

export default BottomSheetTabItems
