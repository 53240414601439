


















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'
import KeyfactsContainer from '~/components/FixContent/Keyfacts/KeyfactsContainer.vue'
import QuoteBoxContainer from '~/components/FixContent/QuoteBox/QuoteBoxContainer.vue'
import RiskInformationContainer from '~/components/FixContent/RiskInformation/RiskInformationContainer.vue'
import ContactBoxContainer from '~/components/FixContent/ContactBox/ContactBoxContainer.vue'

@Component({
  components: {
    KeyfactsContainer,
    QuoteBoxContainer,
    RiskInformationContainer,
    ContactBoxContainer
  }
})
export default class FixContent extends Vue {
  @Prop()
  private type!: string

  @Prop()
  private project!: ProjectMetaData

  @Prop()
  private projectData!: ProjectData
}
