


























































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ContactboxInterface from '@exporo/financing_project_type_orm/dist/interfaces/Contactbox'

@Component({
  components: {}
})
class AvatarSection extends Vue {
  @Prop()
  data!: ContactboxInterface

  private isMobileOrTablet(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile' || mediaQuery === 'tablet'
  }
}

export default AvatarSection
