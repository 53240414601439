




















import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class DownloadBtn extends Vue {
  @Prop()
  fileUrl!: string

  @Prop()
  fileName!: string
}
