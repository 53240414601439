














import { Component, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'

@Component({
  components: {
    Container
  }
})
class ContactBoxSkeleton extends Vue {}

export default ContactBoxSkeleton
