
















import { Component, Prop, Provide, Vue, Watch } from 'nuxt-property-decorator'
import CardData from '@exporo/financing_project_type_orm/dist/interfaces/CardData'
import { StatusType } from '@exporo/financing_project_type_orm/dist/interfaces/Status'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import { Store, store } from '~/store/Store'
import TrackingHelper from '~/components/Tracking/TrackingHelper'

enum AnimationState {
  IDLE,
  RUNNING,
  STOP
}

@Component
class CtaButton extends Vue {
  @Prop({ default: '#3968af' })
  private color!: string

  @Prop({ default: '#ffffff' })
  private textColor!: string

  @Prop({ default: 'Missing Text' })
  private defaultText!: string

  @Prop({ default: true })
  private isInvestButton!: boolean

  @Prop({ default: '100%' })
  private width!: string

  @Prop({ default: false })
  private floatable!: boolean

  @Prop({ required: true })
  private readonly data!: CardData

  @Prop()
  private readonly project!: ProjectMetaData

  @Prop()
  private url!: string

  @Prop()
  private callback!: () => {}

  private cta!: Element

  private shouldFloatBottom: boolean = false

  private top: number = 0

  private animationState: AnimationState = AnimationState.STOP
  private isScrollUp: boolean = false
  private scrollPos: number = 0

  @Provide()
  private store: Store = store

  mounted() {
    this.cta = (this.$refs.cta as Vue).$el as Element

    if (this.floatable) {
      this.initFloatibility()
    }
  }

  destroyed() {
    if (this.floatable) {
      this.removeFloatibility()
    }
  }

  track() {
    if (this.callback) {
      this.callback()
      return null
    } else if (this.data?.financingCheckoutSlug) {
      TrackingHelper.getInstance({
        singleProjectMetaData: this.project
      }).trackAddToCart()
    }

    window.location.href = this.url
  }

  get disabled(): boolean {
    if (!this.isInvestButton) return false
    switch (store.currentProjectStatusType) {
      // Clickable
      case StatusType.IN_FUNDING:
      case StatusType.COMING_SOON:
        return false

      // Not clickable
      default:
        return true
    }
  }

  get text(): string {
    switch (store.currentProjectStatusType) {
      case StatusType.FUNDED:
        return 'Erfolgreich finanziert'
      case StatusType.REPAID:
        return 'Bereits zurückgezahlt'
      case StatusType.IN_FUNDING:
        return 'Jetzt Investieren'
      case StatusType.COMING_SOON:
        return 'Jetzt Vormerken'
      default:
        return this.defaultText
    }
  }

  @Watch('floatable')
  floatableWatcher(newValue: boolean, oldValue: boolean): void {
    if (newValue === oldValue) {
      return
    }

    if (newValue) {
      this.initFloatibility()
    }
  }

  initFloatibility() {
    const boundingClientRect: DOMRect = this.cta.getBoundingClientRect()
    this.top = boundingClientRect.y

    window.addEventListener('scroll', this.onScroll, {
      passive: true,
      capture: false
    })
  }

  removeFloatibility() {
    this.shouldFloatBottom = false
    if (!this.isMobileOrTablet()) this.$root.$emit('toggle-cta-btn-bar')
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll() {
    if (store.currentProjectStatusType !== StatusType.IN_FUNDING) return null
    const boundingClientRect = document.body.getBoundingClientRect()
    this.isScrollUp = boundingClientRect.top > this.scrollPos
    this.scrollPos = boundingClientRect.top

    this.checkShouldFloat()

    if (this.shouldFloatBottom) {
      if (AnimationState.STOP === this.animationState) {
        this.animationState = AnimationState.RUNNING
        this.cta.className += ' hide-top'

        setTimeout(() => {
          this.cta.className += ' floating'
          this.animationState = AnimationState.IDLE
          if (!this.isMobileOrTablet()) this.cta.className += ' hide-desktop'
        }, 200)
      }
    } else if (AnimationState.IDLE === this.animationState) {
      this.animationState = AnimationState.RUNNING
      this.cta.className = this.cta.className
        .replace(' hide-top', '')
        .replace(' floating', '')
      if (!this.isMobileOrTablet())
        this.cta.className = this.cta.className.replace(' hide-desktop', '')

      setTimeout(() => {
        this.cta.className = this.cta.className.replace(' floating', '')
        this.animationState = AnimationState.STOP
      }, 200)
    }
  }

  private checkShouldFloat() {
    const boundingClientRect: DOMRect = this.cta.getBoundingClientRect()
    if (this.shouldFloatBottom && boundingClientRect.y > 75) {
      const doc = document.documentElement
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

      if (top <= this.top && this.isScrollUp) {
        this.shouldFloatBottom = false
        if (!this.isMobileOrTablet()) this.$root.$emit('toggle-cta-btn-bar')
      }
    } else if (
      !this.shouldFloatBottom &&
      boundingClientRect.y <= 75 &&
      !this.isScrollUp
    ) {
      this.shouldFloatBottom = true
      if (!this.isMobileOrTablet()) this.$root.$emit('toggle-cta-btn-bar')
    }
  }

  private isMobileOrTablet(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile' || mediaQuery === 'tablet'
  }
}

export default CtaButton
