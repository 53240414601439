
































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import QuoteboxInterface from '@exporo/financing_project_type_orm/dist/interfaces/Quotebox'
import QuotationMarkIcon from '~/components/SvgComponents/Icons/QuotationMarkIcon.vue'

@Component({
  components: {
    QuotationMarkIcon,
    Container
  }
})
export default class QuoteBox extends Vue {
  @Prop()
  private data!: QuoteboxInterface | null
}
