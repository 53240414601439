






























import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import { Route } from 'vue-router'
import ModuleGroupInterface from '@exporo/financing_project_type_orm/dist/interfaces/ToggleSectionModules/ModuleGroup'
import ExpansionPanel from '~/components/ToggleableContent/ExpansionPanel.vue'
import { mutations } from '~/store/Store'
import Module from '~/components/ToggleableContent/Module.vue'

const BottomSheetButton = () =>
  import('~/components/BottomSheet/BottomSheetButton.vue')

@Component({
  components: {
    BottomSheetButton,
    ExpansionPanel,
    Module,
    Container
  }
})
class ModuleGroup extends Vue {
  @Prop()
  data!: ModuleGroupInterface

  private hasDetails() {
    let nonTeaserModules = []
    if (this.data.isTeaser && this.data.modules[0]) {
      nonTeaserModules = this.data.modules[0].filter(
        (m) => !m.isTeaser && m.data
      )
    }
    return nonTeaserModules.length > 0
  }

  displayContent() {
    window.location.hash = 'bottomsheet'
    mutations.openBottomSheet(this.data.modules, this.data.title)
  }

  private isMobile(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile'
  }

  @Watch('$route')
  onUrlChange(newVal: Route) {
    if (newVal.hash === '') mutations.closeBottomSheet()
  }
}

export default ModuleGroup
