



























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'

@Component({
  components: {
    Container
  }
})
class BottomSheetButton extends Vue {
  @Prop() private callback!: () => {}
  @Prop() private title!: string
  @Prop({ default: false }) private isTeaser!: boolean
  private textMaxWidth: number = 0

  private isMobile(): boolean {
    const mediaQuery = (this as any).$mq
    return mediaQuery === 'mobile'
  }

  mounted(): void {
    this.textMaxWidth = this.setTextMaxWidth()
  }

  private setTextMaxWidth(): number {
    return (this.$refs.btmsheetbtn as Vue).$el.clientWidth - 75 // minus icon width and padding
  }
}

export default BottomSheetButton
