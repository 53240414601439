















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'

@Component({
  components: {
    Container
  }
})
export default class ExporoClass extends Vue {
  @Prop()
  private exporoClass!: string

  private isIphone5(): boolean {
    return window.innerWidth < 321
  }
}
