













import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'

@Component({
  components: {
    Container
  }
})
class Highchart extends Vue {
  @Prop()
  private data!: string

  private highchartSrc: string = `https://app.everviz.com/embed/${this.data}/`
}

export default Highchart
