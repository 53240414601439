











































import { Component, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import QuotationMarkIcon from '~/components/SvgComponents/Icons/QuotationMarkIcon.vue'

@Component({
  components: {
    QuotationMarkIcon,
    Container
  }
})
class QuoteBoxSkeleton extends Vue {}

export default QuoteBoxSkeleton
