







import { Prop, Vue, Watch } from 'nuxt-property-decorator'
import Component from 'vue-class-component'

import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'
import { Container } from '@exporo/educts-component-grid'
import * as contentful from 'contentful'
import KeyfactsSkeleton from '~/components/FixContent/Keyfacts/KeyfactsSkeleton.vue'
import Keyfacts from '~/components/FixContent/Keyfacts/Keyfacts.vue'

@Component({
  components: {
    Keyfacts,
    KeyfactsSkeleton,
    Container
  }
})
class KeyfactsContainer extends Vue {
  @Prop()
  private projectData!: ProjectData | null

  private data: contentful.EntryFields.RichText | null =
    (this.projectData?.data?.fixContent
      ?.keyfacts as contentful.EntryFields.RichText) || null

  @Watch('projectData')
  private dataChanged(
    newData: ProjectData
    // oldData: ProjectData
  ) {
    this.data =
      (newData?.data?.fixContent
        ?.keyfacts as contentful.EntryFields.RichText) || null
  }
}

export default KeyfactsContainer
