





























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'

const Stage = () =>
  import(/* webpackChunkName: "Stage" */ '~/components/Stage/Stage.vue')

const FixContent = () =>
  import(
    /* webpackChunkName: "FixContent" */ '~/components/FixContent/FixContent.vue'
  )

const ActiveProjects = () =>
  import(
    /* webpackChunkName: "ActiveProjects" */ '~/components/Financing/ActiveProjects.vue'
  )

@Component({
  components: {
    Stage,
    FixContent,
    ActiveProjects
  }
})
class RepaidTemplate extends Vue {
  @Prop()
  private project!: ProjectMetaData

  @Prop()
  private projectData!: ProjectData | null
}

export default RepaidTemplate
