

























import { Component, Vue } from 'nuxt-property-decorator'
import { Container } from '@exporo/educts-component-grid'
import FinancingLogo from '~/components/SvgComponents/Icons/FinancingLogoIcon.vue'

@Component({
  components: {
    FinancingLogo,
    Container
  }
})
export default class Hero extends Vue {
  private isVerticalMobile(): boolean {
    return window.innerWidth < 600
  }
}
