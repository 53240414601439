






import { Component, Vue } from 'nuxt-property-decorator'
import { mutations as mutationstore } from '~/store/Store'
import ProjectDetailTemplate from '~/components/ProjectDetailTemplate/ProjectDetailTemplate.vue'

@Component({
  components: {
    ProjectDetailTemplate
  }
})
class PageProjectDetail extends Vue {
  beforeRouteLeave(_to: object, _from: object, next: Function) {
    mutationstore.closeBottomSheet()
    next()
  }
}

export default PageProjectDetail
